import {
    GET,
    PUT,
    POST,
  } from 'constants/methods';
  
  const service = 'papyrus';
  const books = `/${service}/books`;

  export function downloadRequest(bookID, body) {
    const url = `${books}/${bookID}/downloadRequest`;
    return {
      method: POST,
      url,
      body,
    };
  }
