import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// material-ui
import { withStyles } from '@material-ui/core/styles';

// components
import App from 'components/App';
import NotificationCenter from 'components/NotificationCenter';

// actions
import * as Actions from 'actions';

// styles
import styles from './styles';

// helpers
import { getJsonFromUrl } from 'helpers';

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  downloadRequest: (...args) => dispatch(Actions.downloadRequest(...args)),
});

class Root extends Component {
  static propTypes = {
    classes: PropTypes.object,
    downloadRequest: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const urlParams = getJsonFromUrl(window.location);
    this.state = {
      urlParams,
      bookID: urlParams.bookID,
    };
  }

  render() {
    const {
      downloadRequest,
    } = this.props;

    const {
      bookID,
    } = this.state;

    return (
      <App
        downloadRequest={downloadRequest}
        bookID={bookID}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Root));
