import getenv from 'getenv';

const { _APP_CONFIG } = window;

export const REACT_APP_CI_COMMIT_SHA = getenv('REACT_APP_CI_COMMIT_SHA', '#LOCAL');
export const REACT_APP_CI_COMMIT_TAG = getenv('REACT_APP_CI_COMMIT_TAG', 'X.X.X');

// api
export const REACT_APP_API = _APP_CONFIG.api.endpoint;
export const REACT_APP_API_PREFIX = _APP_CONFIG.api.routePrefix;
export const REACT_APP_API_SIGNIN = _APP_CONFIG.api.signinRoute;
export const REACT_APP_API_SIGNOUT = _APP_CONFIG.api.signoutRoute;
export const REACT_APP_PROD = _APP_CONFIG.api.production;

// front
export const REACT_APP_FRONT_BASE = _APP_CONFIG.front.baseHostname;
export const REACT_APP_FRONT = _APP_CONFIG.front.hostname;

// app
export const REACT_APP_ROOT_BACKGROUND = _APP_CONFIG.app.rootBackground;
export const REACT_APP_LOGO = _APP_CONFIG.app.logo;
export const REACT_APP_FAVICON = _APP_CONFIG.app.favicon;
export const SIGN_UP_ENABLED = _APP_CONFIG.app.signupEnabled;
export const SIGN_UP_ORGANIZATION_TOKEN_ENABLED = _APP_CONFIG.app.signupOrganizationTokenEnabled;
export const RESET_PASSWORD_ENABLED = _APP_CONFIG.app.resetPasswordEnabled;
export const ENV_MESSAGE = _APP_CONFIG.app.message;
export const ORGANIZATION_ENABLED = _APP_CONFIG.app.organizationEnabled;
export const REFERRAL_SENTENCE = _APP_CONFIG.app.referralSentence;

// debug
export const CONSOLE_LOGGER_ENABLED = _APP_CONFIG.debug.consoleLogger;

// theme
export const REACT_APP_PRIMARY_COLOR = _APP_CONFIG.theme.primaryColor;
export const REACT_APP_SECONDARY_COLOR = _APP_CONFIG.theme.secondaryColor;

// client
export const REACT_APP_CLIENT_NAME = _APP_CONFIG.client.name;
export const REACT_APP_CLIENT_WEBSITE = _APP_CONFIG.client.website;
export const REACT_APP_CLIENT_EMAIL = _APP_CONFIG.client.contact;
export const REACT_APP_SUPPORT_EMAIL = _APP_CONFIG.client.support;
export const REACT_APP_CLIENT_COLOR = '#2196f3';

// websocket
export const CENTRIFUGE_USER = _APP_CONFIG.websocket.centrifugeUser;
export const CENTRIFUGE_TIMESTAMP = _APP_CONFIG.websocket.centrifugeTimestamp;
export const CENTRIFUGE_TOKEN = _APP_CONFIG.websocket.centrifugeToken;
export const CENTRIFUGE_BASE_URL = _APP_CONFIG.websocket.centrifugeBaseUrl;

// stripe
export const STRIPE_TOKEN = _APP_CONFIG.stripe.token;
export const STRIPE_IMAGES = _APP_CONFIG.stripe.images;

// googleAnalytics
export const GOOGLE_ANALYTICS_KEY = _APP_CONFIG.googleAnalytics.key;
