import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { EMAIL_REGEXP } from './emailRegexp';

// styles
import styles from './styles';
import { CircularProgress, Typography } from '@material-ui/core';

class App extends Component {
  static propTypes = {
    classes: PropTypes.object,
    bookID: PropTypes.string,
    downloadRequest: PropTypes.func,
  };

  state = {
    email: '',
    valid: false,
    loading: false,
  }

  handleChange = name => (event) => { //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
      valid: EMAIL_REGEXP.test(value),
    });
  };

  async send() {
    const { downloadRequest, bookID } = this.props;
    const { email } = this.state;
    this.setState({ loading: true });
    const resp = await downloadRequest(bookID, { email });
    console.log(resp);
    this.setState({ 
      loading: false,
      success: resp.success,
    });    
  }

  render() {
    const { valid, email, loading, success } = this.state;

    console.log(success);

    if (success !== undefined) {
      if (success) {
        return (
          <Typography
            onClick={() => this.setState({ success: undefined })}
            style={{ color: '#607d8b', cursor: 'pointer' }}
          >
            Check your email to download the document
          </Typography>
        )
      } else {
        return (
          <Typography
            onClick={() => this.setState({ success: undefined })}
            style={{ color: '#f44336', cursor: 'pointer' }}
          >
            Something went wrong, please try again
          </Typography>
        )
      }
    }

    if (loading) {
      return ( <CircularProgress size={30} />);
    }
  
    return (
      <Grid container alignItems="center" style={{ width: '100%' }}>
        <Grid item style={{ flexGrow: 1, paddingRight: 16 }}>
          <TextField 
            label="Your Email"
            value={email}
            fullWidth
            onChange={this.handleChange('email')}
            helperText={!valid && (email.length > 0) ? "Invalid Email Format" : ""}
            error={!valid && (email.length > 0)}
          />
        </Grid>
        <Grid item>
          <Button 
            variant="raised" 
            color="primary" 
            onClick={this.send.bind(this)}
            style={{
              color: '#ffffff!important',
              borderWidth: '10px!important',
              borderColor: '#FE992A',
              borderRadius: '0px',
              letterSpacing: '0px',
              padding: 15,
              fontSize: '15px',
              fontFamily: 'Roboto,Helvetica,Arial,Lucida,sans-serif!important',
              fontWeight: 600,
              backgroundColor: '#FE992A',
              boxShadow: 'box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.55)',
            }}
          >
            DOWNLOAD
          </Button>
        </Grid>
      </Grid>
    )
  }
}
export default withStyles(styles)(App);
