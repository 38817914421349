import { networkAction } from 'helpers/network/networkAction';

import {
  DOWNLOAD_REQUEST
} from 'constants/books';

import * as Api from 'api';

export const downloadRequest = (bookID, body) => async dispatch => networkAction(
  dispatch, DOWNLOAD_REQUEST, Api.downloadRequest, [bookID, body],
);
